import React from "react";
import { withRouter } from "react-router";
import Utils from "../../services/utils.service";

function Summary({ currentMonth }) {
  return (
    <>
      <div className="summary">
        <div className="summary__revenus">
          <h4>Revenus</h4>
          <p>{Utils.formatPrice(currentMonth.revenus)} €</p>
        </div>
        <div className="summary__depenses">
          <h4>Dépenses</h4>
          <p>{Utils.formatPrice(currentMonth.depenses)} €</p>
        </div>
        <div className="summary__balance">
          <h4>Balance</h4>
          <p>{Utils.formatPrice(currentMonth.balance)} €</p>
        </div>
        <div className="summary__beginmonth">
          <h4>Solde : Début de mois</h4>
          <p>{Utils.formatPrice(currentMonth.solde_begin)} €</p>
        </div>
        <div className="summary__today">
          <h4>Solde : Aujourd'hui</h4>
          <p>{Utils.formatPrice(currentMonth.solde_today)} €</p>
        </div>
        <div className="summary__endmonth">
          <h4>Solde : Fin de mois</h4>
          <p>{Utils.formatPrice(currentMonth.solde_end)} €</p>
        </div>
      </div>
    </>
  );
}

export default withRouter(Summary);
