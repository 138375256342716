import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { withRouter } from "react-router";

function Gestion({ ...props }) {
  const newMove = () => {
    props.history.push("/moves");
  };

  return (
    <div className="gestion">
      <AddIcon onClick={newMove} />
    </div>
  );
}

export default withRouter(Gestion);
