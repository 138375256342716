import "./App.scss";
import React from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Bank from "./components/Bank";
import Home from "./components/Home";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";

import AuthService from "./services/auth.service";
import BankService from "./services/bank.service";
import CreateMove from "./components/Moves/CreateMove";
import EditMove from "./components/Moves/EditMove";
import Gestion from "./components/Gestion";

function App() {
  const [menuVisible, setMenuVisible] = useState(true);
  const [pageTitle, setPageTitle] = useState({
    content: "",
  });
  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentMonth, setCurrentMonth] = useState(undefined);

  useEffect(() => {
    AuthService.getCurrentUser(setCurrentUser);
    if (currentUser) {
      BankService.getCurrentMonth(setCurrentMonth);
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <Sidebar
          menuVisible={menuVisible}
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          setCurrentMonth={setCurrentMonth}
        />
        <Topbar pageTitle={pageTitle} />
        {currentMonth && <Gestion />}
        <Switch>
          <Route exact path="/">
            <Home
              setMenuVisible={setMenuVisible}
              setPageTitle={setPageTitle}
              currentUser={currentUser}
            />
          </Route>
          <Route exact path="/bank">
            <Bank
              setMenuVisible={setMenuVisible}
              setPageTitle={setPageTitle}
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
            />
          </Route>
          <Route path="/bank/:month">
            <Bank
              setMenuVisible={setMenuVisible}
              setPageTitle={setPageTitle}
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
            />
          </Route>
          <Route exact path="/moves">
            <CreateMove
              setMenuVisible={setMenuVisible}
              setPageTitle={setPageTitle}
              currentUser={currentUser}
            />
          </Route>
          <Route path="/moves/:id">
            <EditMove
              setMenuVisible={setMenuVisible}
              setPageTitle={setPageTitle}
              currentUser={currentUser}
              editMove={true}
            />
          </Route>
          <Redirect path="*" to="/" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
