import React, { useRef } from "react";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";

import FormMove from "./FormMove";

function CreateMove({ setPageTitle, setMenuVisible, currentUser, ...props }) {
  const formRef = useRef();

  useEffect(() => {
    setPageTitle({
      content: <h1 className="main__title">Nouveau Mouvement</h1>,
    });
    setMenuVisible(true);
  }, [setPageTitle, setMenuVisible]);

  return (
    <div className="movement">
      {currentUser ? (
        <FormMove formRef={formRef} newMove />
      ) : (
        <p>Vous n'êtes pas connecté</p>
      )}
    </div>
  );
}

export default withRouter(CreateMove);
