import axiosInstance from "../axiosInstance";

const login = async (data) => {
  let endpoint = "/sessions";
  return axiosInstance.post(endpoint, data, { credentials: "include" });
};

const logout = async (currentUser, setCurrentUser, setCurrentMonth) => {
  if (typeof currentUser !== undefined) {
    let endpoint = `/sessions/${currentUser.id}`;
    axiosInstance.delete(endpoint, { credentials: "include" }).then(() => {
      setCurrentUser(undefined);
      setCurrentMonth(undefined);
    });
  }
};

const getCurrentUser = async (setCurrentUser) => {
  let endpoint = "/users";
  const result = await axiosInstance(endpoint, { credentials: "include" });
  result.data.data
    ? setCurrentUser(result.data.data.attributes)
    : setCurrentUser(undefined);
};

export default {
  login,
  logout,
  getCurrentUser,
};
