import axiosInstance from "../axiosInstance";
import Utils from "./utils.service";

// const monthName = [
//   "Janvier",
//   "Février",
//   "Mars",
//   "Avril",
//   "Mai",
//   "Juin",
//   "Juillet",
//   "Août",
//   "Septembre",
//   "Octobre",
//   "Novembre",
//   "Décembre",
// ];

// const getCurrentDate = (customDate) => {
//   let date = new Date(Date.now());
//   let year = `${date.getFullYear()}`;
//   let month = date.getMonth() + 1;

//   if (customDate) {
//     year = `${customDate.year}`;
//     month = customDate.month;
//   }

//   month < 10 ? (month = `0${month}`) : (month = `${month}`);
//   let currentDate = {
//     year: year,
//     month: month,
//     month_name: monthName[month - 1],
//   };
//   return currentDate;
// };

const getCurrentMonth = async (setCurrentMonth, customDate) => {
  let currentDate = customDate
    ? Utils.getCurrentDate(customDate)
    : Utils.getCurrentDate();
  let param = `${currentDate.year}${currentDate.month}`;
  let endpoint = `/month/${param}`;

  const result = await axiosInstance(endpoint, { credentials: "include" });
  result.data.entries > 0
    ? setCurrentMonth({ current_date: currentDate, ...result.data })
    : setCurrentMonth(undefined);
};

const createMovement = async (data) => {
  let endpoint = "/movements";
  return axiosInstance.post(endpoint, data, { credentials: "include" });
};

const destroyMovement = async (id) => {
  let endpoint = `/movements/${id}`;
  return axiosInstance.delete(endpoint, { credentials: "include" });
};

const getMovement = async (id) => {
  let endpoint = `/movements/${id}`;
  return axiosInstance(endpoint, { credentials: "include" });
};

const updateMovement = async (id, data) => {
  let endpoint = `/movements/${id}`;
  return axiosInstance.put(endpoint, data, { credentials: "include" });
};

export default {
  getCurrentMonth,
  createMovement,
  getMovement,
  updateMovement,
  destroyMovement,
};
