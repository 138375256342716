import React from "react";
import { useEffect } from "react";
import { withRouter, useParams, Redirect } from "react-router";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Summary from "./Bank/Summary";
import Table from "./Bank/Table";

import BankService from "../services/bank.service";
import Utils from "../services/utils.service";

function Bank({
  setPageTitle,
  setMenuVisible,
  currentUser,
  currentMonth,
  setCurrentMonth,
  ...props
}) {
  let monthParam = useParams();
  useEffect(() => {
    if (Object.keys(monthParam).length === 0) {
      let currentDate = Utils.getCurrentDate();
      monthParam = `${currentDate.year}${currentDate.month}`;
      BankService.getCurrentMonth(setCurrentMonth);
      props.history.push(`/bank/${monthParam}`);
    } else if (
      currentMonth &&
      currentMonth.current_month !== monthParam.month
    ) {
      BankService.getCurrentMonth(
        setCurrentMonth,
        Utils.paramToDate(monthParam.month)
      );
    } else if (!currentMonth) {
      BankService.getCurrentMonth(
        setCurrentMonth,
        Utils.paramToDate(monthParam.month)
      );
    }

    const nextMonth = () => {
      props.history.push(`/bank/${currentMonth.next_month}`);
      BankService.getCurrentMonth(
        setCurrentMonth,
        Utils.paramToDate(currentMonth.next_month)
      );
    };

    const prevMonth = () => {
      props.history.push(`/bank/${currentMonth.prev_month}`);
      BankService.getCurrentMonth(
        setCurrentMonth,
        Utils.paramToDate(currentMonth.prev_month)
      );
    };

    const title = (
      <>
        {currentMonth && (
          <>
            <div
              className={
                currentMonth.prev_month
                  ? "topbar__prev"
                  : "topbar__prev disabled"
              }
            >
              {currentMonth.prev_month ? (
                <ChevronLeftIcon onClick={prevMonth} />
              ) : (
                <ChevronLeftIcon />
              )}
            </div>

            <div className="topbar__month">
              {currentMonth.current_date.month_name}{" "}
              {currentMonth.current_date.year}
            </div>

            <div
              className={
                currentMonth.next_month
                  ? "topbar__next"
                  : "topbar__next disabled"
              }
            >
              {currentMonth.next_month ? (
                <ChevronRightIcon onClick={nextMonth} />
              ) : (
                <ChevronRightIcon />
              )}
            </div>
          </>
        )}
      </>
    );
    setPageTitle({ content: title });
    setMenuVisible(true);
  }, [currentMonth, setPageTitle, setMenuVisible]);

  return (
    <>
      <div className="month-overview">
        {currentUser ? (
          <>
            {currentMonth && (
              <>
                <Summary currentMonth={currentMonth} />
                <Table currentMonth={currentMonth} />
              </>
            )}
          </>
        ) : (
          <p>Vous n'êtes pas connecté</p>
        )}
      </div>
    </>
  );
}

export default withRouter(Bank);
