import React from "react";
import { useState, useEffect } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";

import AuthService from "../services/auth.service";

function Sidebar({
  menuVisible,
  currentUser,
  setCurrentUser,
  setCurrentMonth,
  ...props
}) {
  const [showLinks, setShowLinks] = useState(false);
  const [showNav, setShowNav] = useState(true);

  const handleShowLinks = () => {
    setShowLinks((showLinks) => !showLinks);
  };

  const closeSidebar = () => {
    setShowLinks(false);
  };

  const Logout = async () => {
    AuthService.logout(currentUser, setCurrentUser, setCurrentMonth);
    props.history.push("/");
    window.location.reload();
  };

  useEffect(() => {
    setShowNav(menuVisible);
  }, [menuVisible]);

  return (
    <nav className={`sidebar ${showNav ? "show-nav" : "hide-nav"}`}>
      <div
        className={`sidebar__links ${showLinks ? "show-links" : "hide-links"}`}
      >
        <h1 className="sidebar__title">Compta Perso</h1>
        <h3 className="sidebar__subtitle">Mes Comptes</h3>
        <ul>
          <li>
            <NavLink
              exact
              to="/bank"
              activeClassName="active"
              className="sidebar__menu"
              onClick={closeSidebar}
            >
              Synthèse
            </NavLink>
          </li>
          <li>
            <Link
              exact
              to="/moves"
              className="sidebar__menu"
              onClick={closeSidebar}
            >
              Nouveau mouvement
            </Link>
          </li>
        </ul>
        {currentUser && (
          <>
            <h3 className="sidebar__subtitle">{currentUser.name}</h3>
            <ul>
              <li className="sidebar__menu" onClick={Logout}>
                Déconnexion
              </li>
            </ul>
          </>
        )}
      </div>
      <button className="sidebar__burger" onClick={handleShowLinks}>
        <span className={`burger-bar ${showLinks ? "expand" : ""}`}></span>
      </button>
    </nav>
  );
}

export default withRouter(Sidebar);
