import React, { useState, useEffect, useRef } from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { withRouter } from "react-router";
import Utils from "../../services/utils.service";
import DeleteMove from "../Moves/DeleteMove";

function Table({ currentMonth, ...props }) {
  const [deleteMovement, setDeleteMovement] = useState({
    move: {},
    visibility: "hide",
  });
  const editMovement = (id) => {
    props.history.push(`/moves/${id}`);
  };

  const deleteRef = useRef();

  const askDeleteMovement = (move) => {
    setDeleteMovement({ move: move, visibility: "" });
  };

  useEffect(() => {
    if (deleteMovement.visibility === "" && deleteRef.current) {
      // fieldRef.current.scrollIntoView();
      deleteRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [deleteMovement]);

  return (
    <>
      <div className="table-header">
        <div className="table-header__date">Jour</div>
        <div className="table-header__libelle">Libellé</div>
        <div className="table-header__amount">Méthode</div>
        <div className="table-header__amount">Mouvement</div>
        <div></div>
      </div>
      {currentMonth &&
        currentMonth.moves.data.map((m) => (
          <div className="table-content">
            <div className="table-content__date">
              {Utils.day(m.attributes.date)}
            </div>
            <div className="table-content__libelle">{m.attributes.detail}</div>
            <div className="table-content__amount">
              {m.attributes.payment_type}
            </div>
            {/* <div className="table-content__amount">
              {m.attributes.move_type === "credit" &&
                Utils.formatPrice(m.attributes.amount)}
            </div>
            <div className="table-content__amount">
              {m.attributes.move_type === "debit" &&
                Utils.formatPrice(m.attributes.amount)}
            </div> */}
            <div className="table-content__amount">
              {m.attributes.move_type === "credit" ? (
                <span className="success">
                  +{Utils.formatPrice(m.attributes.amount)}
                </span>
              ) : (
                <span className="danger">
                  {Utils.formatPrice(m.attributes.amount)}
                </span>
              )}
              {" €"}
            </div>
            <div className="table-content__controls">
              <EditIcon onClick={() => editMovement(m.id)} />
              <DeleteForeverIcon
                onClick={() => askDeleteMovement(m.attributes)}
              />
            </div>
          </div>
        ))}
      <DeleteMove
        setDeleteMovement={setDeleteMovement}
        Visibility={deleteMovement.visibility}
        Move={deleteMovement.move}
        deleteRef={deleteRef}
      />
    </>
  );
}

export default withRouter(Table);
