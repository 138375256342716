import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";

import BankService from "../../services/bank.service";
import FormMove from "./FormMove";

function EditMove({
  setPageTitle,
  setMenuVisible,
  currentUser,
  editMove,
  ...props
}) {
  const [move, setMove] = useState({
    date: "",
    amount: "",
    move_type: "debit",
    recurrent: 1,
    detail: "",
  });

  let idx = useParams();
  const formRef = useRef();

  useEffect(() => {
    setPageTitle({
      content: <h1 className="main__title">Édition Mouvement</h1>,
    });
    setMenuVisible(true);
    BankService.getMovement(idx.id).then((res) => {
      let m = res.data.data.attributes;
      setMove({
        date: m.date,
        amount: m.amount,
        move_type: m.move_type,
        recurrent: m.recurrent,
        detail: m.detail,
      });
    });
  }, [setPageTitle, setMenuVisible]);

  return (
    <div className="movement">
      {currentUser && move ? (
        <FormMove Move={move} formRef={formRef} editMove={editMove} idx={idx} />
      ) : (
        <p>Vous n'êtes pas connecté</p>
      )}
    </div>
  );
}

export default withRouter(EditMove);
