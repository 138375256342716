import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";

import BankService from "../../services/bank.service";
import Utils from "../../services/utils.service";

function FormMove({ Move, formRef, editMove, newMove, idx, ...props }) {
  const [loaded, setLoaded] = useState(false);
  const [isRecurrent, setIsRecurrent] = useState(false);

  const watchRecurrent = (e) => {
    e.target.checked ? setIsRecurrent(true) : setIsRecurrent(false);
  };

  const [errorAmount, setErrorAmount] = useState({
    status: false,
    message: "",
  });

  const defaultMoveType = editMove ? Move.move_type : "debit";
  const defaultDate = editMove ? Move.date : Utils.formDate();
  const defaultRecurrent = editMove ? Move.recurrent : 1;
  const defaultPaymentType = editMove ? Move.payment_type : "CB";
  const {
    register,
    reset,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      move_type: defaultMoveType,
      date: defaultDate,
      recurrent: defaultRecurrent,
      payment_type: defaultPaymentType,
    },
  });

  const watchMoveType = editMove
    ? watch("move_type", Move.move_type)
    : watch("move_type");

  const onSubmit = (data) => {
    let dateParam = `${data.date.slice(0, 4)}${data.date.slice(5, 7)}`;
    let moveRequest = editMove
      ? BankService.updateMovement(idx.id, data)
      : BankService.createMovement(data);

    moveRequest.then(
      () => {
        props.history.push(`/bank/${dateParam}`);
        window.location.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const checkAmount = (value) => {
    if (watchMoveType === "debit") {
      if (parseInt(value) <= 0) {
        setErrorAmount({ message: "", status: false });
        return true;
      } else {
        setErrorAmount({
          status: true,
          message: "Le montant doit être négatif",
        });
        return errorAmount.message;
      }
    } else {
      if (parseInt(value) >= 0) {
        setErrorAmount({ message: "", status: false });
        return true;
      } else {
        setErrorAmount({
          status: true,
          message: "Le montant doit être positif",
        });
        return errorAmount.message;
      }
    }
  };

  useEffect(() => {
    if (editMove) {
      reset({
        amount: Move.amount,
        date: Move.date,
        detail: Move.detail,
        move_type: Move.move_type,
        recurrent: Move.recurrent,
      });
    }
    setLoaded(true);
  }, [loaded, Move]);

  return (
    <>
      {loaded && (
        <div className="form">
          <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <div className="form__group">
              <label className="form__label">Type</label>
              <label className="form__label--radio">
                <input
                  {...register("move_type", {
                    validate: {
                      checkAmount: () => checkAmount(getValues("amount")),
                    },
                  })}
                  type="radio"
                  value="credit"
                />
                Crédit
              </label>
              <label>
                <input
                  {...register("move_type", {
                    validate: {
                      checkAmount: () => checkAmount(getValues("amount")),
                    },
                  })}
                  type="radio"
                  value="debit"
                />
                Débit
              </label>
            </div>

            <div className="form__group">
              <label className="form__label">
                Libellé
                <input
                  {...register("detail", {
                    required: "Veuillez indiquer un libellé de mouvement.",
                    minLength: {
                      value: 3,
                      message: "Libellé trop court. 3 caractères minimum",
                    },
                  })}
                  type="text"
                  className={errors.detail && "error"}
                />
                {errors.detail && (
                  <p className="error">{errors?.detail?.message}</p>
                )}
              </label>
            </div>
            <div className="form__group">
              <label className="form__label">
                Montant
                <input
                  {...register("amount", {
                    required: "Veuillez indiquer un montant.",
                    validate: {
                      checkAmount: (value) => checkAmount(value),
                    },
                  })}
                  type="text"
                  className={errors.amount && "error"}
                />
                {errors.amount && (
                  <p className="error">{errors?.amount?.message}</p>
                )}
              </label>
            </div>
            <div className="form__group">
              <label className="form__label">
                Méthode de Paiement
                <select
                  {...register("payment_type", {
                    required: "Veuillez indiquer la méthode de paiement.",
                  })}
                >
                  <option value="CB">CB</option>
                  <option value="Virement">Virement</option>
                  <option value="Paypal">Paypal</option>
                  <option value="Chèque">Chèque</option>
                </select>
                {errors.payment_type && (
                  <p className="error">{errors?.payment_type?.message}</p>
                )}
              </label>
            </div>

            <div className="form__group">
              <label className="form__label">
                Date
                <input
                  {...register("date", {
                    required:
                      "Veuillez indiquer une date au format AAAA-MM-JJ.",
                    pattern: {
                      value: /\d{4}-\d{2}-\d{2}/i,
                      message: "La date doit être au format AAAA-MM-JJ.",
                    },
                  })}
                  type="text"
                  className={errors.date && "error"}
                />
                {errors.date && (
                  <p className="error">{errors?.date?.message}</p>
                )}
              </label>
            </div>
            {newMove && (
              <div className="form__group">
                <label className="form__label">
                  <input type="checkbox" onChange={watchRecurrent} />
                  Récurrent
                </label>
              </div>
            )}
            {isRecurrent && (
              <div className="form__group">
                <label className="form__label">
                  Durant combien de mois
                  <input
                    type="text"
                    className={errors.recurrent && "error"}
                    {...register("recurrent", {
                      pattern: {
                        value: /\d{1,2}/i,
                        message:
                          "La durée maximum est de 12 mois, en chiffre uniquement.",
                      },
                    })}
                  />
                  {errors.recurrent && (
                    <p className="error">{errors?.recurrent?.message}</p>
                  )}
                </label>
              </div>
            )}

            <button type="submit">Enregistrer</button>
          </form>
        </div>
      )}
    </>
  );
}

export default withRouter(FormMove);
