const monthName = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const paramToDate = (str) => {
  let year = str.slice(0, 4);
  let month = str.slice(4, 6);
  let customDate = {
    year: year,
    month: month,
    month_name: monthName[parseInt(month)],
  };
  return customDate;
};

const getCurrentDate = (customDate) => {
  let date = new Date(Date.now());
  let year = `${date.getFullYear()}`;
  let month = date.getMonth() + 1;

  if (customDate) {
    year = `${customDate.year}`;
    month = parseInt(customDate.month);
  }

  month < 10 ? (month = `0${month}`) : (month = `${month}`);
  let currentDate = {
    year: year,
    month: month,
    month_name: monthName[month - 1],
  };
  return currentDate;
};

const formDate = () => {
  let date = new Date(Date.now());
  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let day = ("0" + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

const formatPrice = (amount) => {
  return (Math.round(amount * 100) / 100).toFixed(2);
};

const day = (str) => {
  let day = str.substring(str.length - 2, str.length);
  return day;
};

export default { getCurrentDate, formatPrice, day, formDate, paramToDate };
