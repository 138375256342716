import React from "react";
import { useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import LoginForm from "./LoginForm";

function Home({ setPageTitle, setMenuVisible, currentUser, ...props }) {
  useEffect(() => {
    setMenuVisible(false);
    setPageTitle({
      content: <h1 className="main__title">Compta Perso</h1>,
    });
    console.log(currentUser);
  }, [setMenuVisible, setPageTitle]);

  return (
    <div className="home">
      {currentUser ? (
        <>
          <Redirect to="/bank" />
        </>
      ) : (
        <LoginForm />
      )}
    </div>
  );
}

export default withRouter(Home);
