import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router";
import AuthService from "../services/auth.service";

function LoginForm(props) {
  const [errorLogin, setErrorLogin] = useState(false);
  const formRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    AuthService.login(data).then(
      (res) => {
        if (res.data.code && res.data.code === 422) {
          setErrorLogin(true);
        } else {
          setErrorLogin(false);
          props.history.push(`/bank`);
          window.location.reload();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div className="form">
      <h2>Sign In</h2>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        {errorLogin && (
          <div className="alert">
            <p className="alert__error">
              Nom d'utilisateur ou Mot de passe incorrect.
            </p>
          </div>
        )}

        <div className="form__group">
          <label className="form__label">
            Nom d'utilisateur
            <input
              {...register("email", {
                required: "Veuillez indiquer votre nom d'utilisateur.",
              })}
              type="text"
              className={errors.email && "error"}
            />
            {errors.email && <p className="error">{errors?.email?.message}</p>}
          </label>
        </div>
        <div className="form__group">
          <label className="form__label">
            Mot de passe
            <input
              {...register("password", {
                required: "Veuillez indiquer votre mot de passe.",
              })}
              type="password"
              className={errors.password && "error"}
            />
            {errors.password && (
              <p className="error">{errors?.password?.message}</p>
            )}
          </label>
        </div>
        <button type="submit">Enregistrer</button>
      </form>
    </div>
  );
}

export default withRouter(LoginForm);
