import React from "react";
import BankService from "../../services/bank.service";

function DeleteMove({ Visibility, Move, setDeleteMovement, deleteRef }) {
  const closeModal = () => {
    setDeleteMovement({ move: {}, visibility: "hide" });
  };

  const handleDestroyMovement = () => {
    BankService.destroyMovement(Move.id).then(() => {
      closeModal();
      window.location.reload();
    });
  };
  return (
    <div className={`modal-container ${Visibility}`} ref={deleteRef}>
      <div className="modal-container__modal">
        <h3>Êtes-vous sûr de vouloir supprimer définitivement ?</h3>
        <p>{Move.detail}</p>
        <div className="button-container">
          <button
            className="button button__right danger"
            onClick={handleDestroyMovement}
          >
            Supprimer
          </button>
          <button className="button button__left" onClick={closeModal}>
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteMove;
